import { useBreakpointValue } from '@chakra-ui/react';
import { trackAnalyticsCustomEvent, CustomAnalyticsEvent } from '@ifixit/analytics';
import { safeLocalStorage } from '@ifixit/utils';
import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import type { Dispatch, SetStateAction, RefObject } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<Value = any>(value: Value, delay: number): Value {
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(() => {
      const handler = setTimeout(() => {
         setDebouncedValue(value);
      }, delay);
      return () => {
         clearTimeout(handler);
      };
   }, [value, delay]);

   return debouncedValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebouncedCallback<Args extends any[]>(
   callback: (...args: Args) => void,
   wait: number
) {
   const savedCallbackRef = useRef(callback);
   const argsRef = useRef<Args>();
   const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

   function cleanup() {
      if (timeoutRef.current) {
         clearTimeout(timeoutRef.current);
      }
   }

   useEffect(() => {
      savedCallbackRef.current = callback;
   }, [callback]);

   useEffect(() => cleanup, []);

   return function debouncedCallback(...args: Args) {
      argsRef.current = args;

      function execute() {
         if (argsRef.current) {
            savedCallbackRef.current(...argsRef.current);
         }
      }

      cleanup();

      timeoutRef.current = setTimeout(execute, wait);
   };
}

export function usePrevious<T>(value: T): T | undefined {
   const ref = useRef<T>();

   useEffect(() => {
      ref.current = value;
   }, [value]);

   return ref.current;
}

export const useIsomorphicLayoutEffect =
   typeof document === 'undefined' ? useEffect : useLayoutEffect;

export function useIsMountedState() {
   const [isMounted, setIsMounted] = useState(false);

   useEffect(() => {
      setIsMounted(true);
   }, []);

   return isMounted;
}

export function useIsMounted() {
   const isMountedRef = useRef(false);

   useEffect(() => {
      isMountedRef.current = true;
      return () => {
         isMountedRef.current = false;
      };
   }, []);

   return isMountedRef.current;
}

interface UsePreloadImage {
   preload(url: string): void;
   isLoaded: boolean;
   isError: boolean;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   error: any;
}

export function usePreloadImage(): UsePreloadImage {
   const [state, setState] = useSafeSetState<{
      status: 'idle' | 'loading' | 'loaded' | 'error';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
   }>({
      status: 'idle',
      error: null,
   });

   const preload = useCallback((url: string) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
         setState({
            status: 'loaded',
            error: null,
         });
      };
      img.onerror = err => {
         setState({
            status: 'error',
            error: err,
         });
      };
   }, []);

   return {
      preload,
      isError: state?.status === 'error',
      isLoaded: state?.status === 'loaded',
      error: state?.error,
   };
}

/**
 * This hook is similar to Chakra `useBreakpointValue` hook, but it's designed to work with server
 * side rendering, namely it doesn't show warnings on the initial render.
 */
export function useSSRBreakpointValue<Value>(
   values: Value[] | Partial<Record<string, Value>>,
   defaultBreakpoint?: string | undefined
) {
   const isMounted = useIsMountedState();
   const breakpointValue = useBreakpointValue(values, defaultBreakpoint);
   return isMounted ? breakpointValue : defaultBreakpoint;
}

/**
 * A simple hook to store user preferences in local storage.
 * @param key localStorage key
 * @returns [value, setValue] tuple where `value` is the current value and `setValue` is a function to update it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useExpiringLocalPreference<Data = any>(
   key: string,
   defaultData: Data,
   expireInDays: number,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   validator: (data: any) => Data | null
): [Data, (data: Data) => void] {
   type ExpiringData = {
      value: Data;
      expires: number;
   };

   const [data, setData] = useState(defaultData);

   useEffect(() => {
      const serializedData = safeLocalStorage.getItem(key);
      if (serializedData != null) {
         try {
            const data = JSON.parse(serializedData) as ExpiringData;
            const expiresAt = Number.isInteger(data?.expires) ? data.expires : 0;
            const validData = validator(data?.value);
            if (validData !== null && expiresAt && Date.now() < expiresAt) {
               setData(validData);
            } else {
               safeLocalStorage.removeItem(key);
            }
         } catch {
            safeLocalStorage.removeItem(key);
         }
      }
   }, []);

   const setAndSave = (data: Data) => {
      setData(data);
      const expiringData = {
         value: data,
         expires: Date.now() + expireInDays * 1000 * 86_400,
      } as ExpiringData;
      const serializedData = JSON.stringify(expiringData);
      safeLocalStorage.setItem(key, serializedData);
   };

   return [data, setAndSave];
}

export function useSafeSetState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
   const [state, setState] = useState(initialState);

   const mountedRef = useRef(false);
   useEffect(() => {
      mountedRef.current = true;
      return () => {
         mountedRef.current = false;
      };
   }, []);
   const safeSetState = useCallback<Dispatch<SetStateAction<T>>>(
      args => {
         if (mountedRef.current) {
            return setState(args);
         }
      },
      [mountedRef, setState]
   );

   return [state, safeSetState];
}

/**
 * Creates a decoupled state value that is kept is sync with the provided state.
 * The purpose of this hook is to make an input field feel more responsive when the
 * state update depends on async requests.
 * @param state The state that is being decoupled
 * @returns The decoupled state
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDecoupledState<Type = any>(state: Type): [Type, Dispatch<SetStateAction<Type>>] {
   const [decoupledState, setDecoupledState] = useState(state);

   useEffect(() => {
      setDecoupledState(state);
   }, [state]);

   return [decoupledState, setDecoupledState];
}

interface UseOnScreenOptions {
   rootMargin?: string;
   initialOnScreen?: boolean;
}

export function useOnScreen(ref: RefObject<HTMLElement>, options?: UseOnScreenOptions) {
   const [isIntersecting, setIntersecting] = useState(options?.initialOnScreen ?? false);
   useEffect(() => {
      const observer = new IntersectionObserver(
         ([entry]) => {
            setIntersecting(entry.isIntersecting);
         },
         {
            rootMargin: options?.rootMargin || '0px',
         }
      );
      if (ref.current) {
         observer.observe(ref.current);
      }
      return () => {
         if (ref.current) observer.unobserve(ref.current);
      };
   }, []);
   return isIntersecting;
}

export function useIsScrolledPast(ref: RefObject<HTMLElement>) {
   const [isScrolledPast, setIsScrolledPast] = useState(false);

   useEffect(() => {
      const handleScroll = () => {
         if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setIsScrolledPast(rect.bottom < 0);
         }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, [ref]);

   return isScrolledPast;
}

/**
 * Hook that returns a ref for an HTML element and uses Intersection Observer to check if the ref was ever visible.
 * Calls trackAnalyticsCustomEvent when the element becomes visible for the first time.
 *
 * Example usage:
 *
 * ```tsx
 * const [isVisible, ref] = useVisibilityObserver({ eventCategory: 'component-visible', eventAction: 'component-visible - Visible' });
 *
 * return <div ref={ref}>{isVisible ? 'Visible' : 'Not Visible'}</div>;
 * ```
 */
export function useVisibilityObserver(
   props: CustomAnalyticsEvent
): [boolean, RefObject<HTMLDivElement>] {
   const [isVisible, setIsVisible] = useState(false);
   const ref = useRef<HTMLDivElement>(null);

   useEffect(() => {
      const observer = new IntersectionObserver(
         entries => {
            entries.forEach(entry => {
               if (entry.isIntersecting && !isVisible) {
                  setIsVisible(true);
                  trackAnalyticsCustomEvent(props);
                  observer.disconnect();
               }
            });
         },
         { threshold: 0.1 } // at least 10% of el is in view
      );

      if (ref.current) {
         observer.observe(ref.current);
      }

      return () => {
         observer.disconnect();
      };
   }, [isVisible, props]);

   return [isVisible, ref] as const;
}
